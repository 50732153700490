body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.grecaptcha-badge {
  visibility: hidden !important;
}


/*TON connect styles overrides*/
[data-tc-connect-button="true"]	{
  width: 100%;
  justify-content: center !important;
  border-radius: 50px !important;
  padding: 0 8px !important;
  box-shadow: 0px 3px 3px 0px #00000026;
}
/*[data-tc-connect-button="true"] svg	{*/
/*  display: none !important;*/
/*}*/

[data-tc-dropdown-button="true"] {
  min-width: auto !important;
  padding: 0 8px !important;
  border-radius: 50px !important;
  height: 33px !important;
}
[data-tc-dropdown-button="true"] [data-tc-text="true"] {
  font-size: 10px !important;
}
[data-tc-dropdown-button="true"] svg {
  display: none !important;
}
[data-tc-dropdown-button="true"] [data-tc-text="true"] {
  font-size: 10px !important;
}

/*[data-tc-connect-button="true"] [data-tc-text="true"] {*/
/*  font-size: 10px !important;*/
/*}*/

[data-tc-button="true"]:hover {
  transform: unset !important;
}
[data-tc-button="true"]:active {
  transform: unset !important;
}

[data-tc-wallets-modal-container="true"] {
  z-index: 9999 !important;
}
[data-tc-dropdown-container="true"] {
  z-index: 9998 !important;
}
[data-tc-modal="true"] {
  z-index: 9999 !important;
}

[data-tc-dropdown="true"] [data-tc-text="true"] {
  font-size: 10px !important;
}
[data-tc-actions-modal-container="true"] {
  z-index: 9999 !important;
}
[data-tc-confirm-modal="true"] {
  z-index: 9999 !important;
}

@media (max-width: 360px) {
  [data-tc-dropdown-button="true"] [data-tc-text="true"] {
    font-size: 10px !important;
  }
  /*[data-tc-connect-button="true"] [data-tc-text="true"] {*/
  /*  font-size: 10px !important;*/
  /*}*/
  [data-tc-dropdown="true"] [data-tc-text="true"] {
    font-size: 10px !important;
  }
  [data-tc-dropdown="true"] [data-tc-text="true"] {
    font-size: 10px !important;
  }
}

